// import package
import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import SocketContext from "../../lib/SocketContext";
import useAuth from "utils/hooks/useAuth";
import { useNavigate } from "react-router-dom";
// import action
// import { getUserData } from "../../actions/users";

const HelperRoute = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const socketContext = useContext(SocketContext);
  // redux-state
  const { signedIn } = useSelector((state) => state.auth.session);
  const { signOut } = useAuth();
  // function

  useEffect(() => {
    // setTradeTheme(dispatch, theme);
    if (signedIn) {
      // getUserData(dispatch);
    }
  }, [signedIn]);

  useEffect(() => {
    socketContext.socket.on("FORCE_LOGOUT", (result) => {
      console.log(result, "__result");
      let token = localStorage.getItem("user_token");
      if (result && token != result) {
        signOut()
      }
    });
  }, []);
  return <div />;
};

export default HelperRoute;
